import { Box, styled, useMediaQuery } from '@mui/material';
import { useGetWhiteLabel } from '@portal-middleware/hooks';

import { FC, useMemo } from 'react';

import { Error404Icon } from './icon';
import { Link } from './Link';
import { Text } from './Text';

export type PageNotFoundProps = {
  basePath?: string;
  default?: boolean;
  defaultPath?: string;
  legacyPaths?: (string | RegExp)[];
  supportEmail?: string;
};

const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
  padding: theme.spacing(8),
  textAlign: 'center',
  width: '100vw'
}));

const StyledError404Icon = styled(Error404Icon)(({ theme }) => ({
  height: theme.spacing(32),
  width: theme.spacing(32)
}));

const legacyPaths = [/^\/api\//, /^\/auth\/api\//];

const StyledLink = styled(Link)(() => ({
  display: 'inline-block'
}));

export const PageNotFound: FC<PageNotFoundProps> = props => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const whitelabel = useGetWhiteLabel({
    query: {
      cacheTime: 10 * 1000 * 60,
      enabled: !props.supportEmail
    }
  });

  const isRedirectPath = useMemo(
    () =>
      [
        ...(props.basePath ? [props.basePath] : []),
        ...(process.env.NODE_ENV === 'development' ? ['/'] : [])
      ].some(p => p === window.location.pathname),
    [props.basePath]
  );

  const isLegacyPath = useMemo(
    () =>
      [...legacyPaths, ...(props.legacyPaths ?? [])].some(p => {
        return typeof p === 'string'
          ? window.location.pathname === p
          : p.test(window.location.pathname);
      }),
    [props.legacyPaths]
  );

  /**
   * redirect to the index path if path is props.basePath or "/" (dev only)
   * otherwise, show the page not found error.
   */
  if (isRedirectPath && props.defaultPath) {
    window.location.pathname = props.defaultPath;
    return null;
  }

  /**
   * redirect to the index path if path is props.basePath or "/" (dev only)
   * otherwise, show the page not found error.
   */
  if (isLegacyPath) {
    window.location.href = `${window.location.origin}${window.location.pathname}`;
    return null;
  }

  return (
    <Container data-component='PageNotFound' role='presentation'>
      <StyledError404Icon color='dijon' />
      <Text
        color='black'
        component='h1'
        data-testid='PageNotFound__title'
        marginX='auto'
        mt={isMobile ? 8 : 12}
        variant={isMobile ? 'e2' : 'b2'}>
        Page Not Found
      </Text>
      <Box mt={10}>
        The page you were looking for does not exist. We apologize for any
        inconvenience. We are continually working to improve the site.
      </Box>
      <Box mt={4}>
        If you need assistance, please contact us at{' '}
        <StyledLink
          data-testid='PageNotFound__supportEmail'
          to={`mailto:${
            props.supportEmail ?? whitelabel.data?.support?.participant?.email
          }`}>
          {props.supportEmail ?? whitelabel.data?.support?.participant?.email}
        </StyledLink>
      </Box>
    </Container>
  );
};

PageNotFound.displayName = 'PageNotFound';
