import axios from 'axios';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import Cookies from 'js-cookie';

import { isNativeMobile } from './isNativeMobile';

export const cleanUpStaleCookies = () => {
  const attributes = {
    domain: `.${window.location.host.split('.').slice(-2).join('.')}`,
    path: '/'
  };
  Cookies.remove('isLoginDisclosure', attributes);
  Cookies.remove('token', attributes);
  Cookies.remove('tokenExpiration', attributes);
  Cookies.remove('refreshToken', attributes);
};

export const logout = async (timeout?) => {
  const isNative = await isNativeMobile();
  let token;
  if (!isNative) {
    token = Cookies.get('token');
  } else {
    const mobileToken = await SecureStoragePlugin.get({ key: 'token' });
    const parsedToken = JSON.parse(mobileToken?.value);
    token = `${parsedToken.token_type} ${parsedToken.access_token}`;
  }
  cleanUpStaleCookies();

  try {
    await axios.get('/auth/api/v2/logout', {
      headers: {
        Authorization: token
      }
    });
    if (!isNative) {
      window.location.href = `${window.location.origin}${timeout === true ? `?error=TIMEOUT` : ''}`;
    } else {
      await SecureStoragePlugin.remove({ key: 'token' });
      await SecureStoragePlugin.remove({ key: 'tokenExpiration' });
      window.location.assign(
        `capacitor://localhost/${timeout === true ? `?error=TIMEOUT` : ''}`
      );
    }
  } catch (e) {
    console.error('Failed to logout', e);
    window.location.href = window.location.origin;
  }
};
